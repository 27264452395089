<div class="dialog-container">
  <h2>Insert personalized tag</h2>
  <div class="body-regular" style="padding-bottom: 24px">Insert tags to personalize the message to your audience</div>
  <hr style="margin: -0px -24px" />
  <div class="input-wrapper">
    <ng-container *ngIf="selectedVariable">
      <div (click)="selectedVariable = false">
        <img style="opacity: 0.3" height="24px" width="24px" [src]="iconService.Icon.left_1" />
        <button class="back-button">Back</button>
      </div>
    </ng-container>
    <ng-container *ngIf="!selectedVariable">
      <img style="opacity: 0.3" height="24px" width="24px" [src]="iconService.Icon.magnifier" />
      <input (input)="onSearchChange($event.target)" class="input-field" placeholder="Search for personalized tag" type="text" [value]="''" />
    </ng-container>
  </div>
  <hr style="margin: -0px -24px" />
</div>
<div class="data-container">
  <ng-container *ngIf="!selectedVariable">
    <div *ngFor="let data of filteredPropertiesList">
      <div class="flex-row entryfields" (click)="selectVariable(data)">
        <div class="body-regular">{{ data.displayName }}</div>
        <img [src]="iconService.Icon.right_1" />
      </div>
      <hr style="margin: 16px -24px" />
    </div>
  </ng-container>
  <ng-container *ngIf="selectedVariable">
    <div class="flex-column" style="gap: 24px">
      <h3>{{ selectedDynamicVariable.displayName }}</h3>
      <div class="flex-row" style="gap: 8px">
        <app-ag-checkbox-v2 (valueChange)="toggleHideMissingData($event)" [initValue]="hideIfMissing"></app-ag-checkbox-v2>
        <div class="flex-column">
          <div class="body-medium">Hide when data is missing</div>
          <div class="body-regular">Don’t show any text if the data for [tag] is missing from a recipient.</div>
        </div>
      </div>
      <ng-container *ngIf="!hideIfMissing">
        <div class="flex-column" style="gap: 8px">
          <div class="body-medium">Default value</div>
          <div class="grey-input-wrapper">
            <input placeholder="Type here" (change)="changeDefaultValue($event.target)" type="text" [value]="defaultValue" class="grey-input-field" />
          </div>
          <div class="flex-row">
            <img height="12px" width="12px" [src]="iconService.Icon.i_letter_1" />
            <div class="label-regular">Add the text you’d like to show recipients when the data is missing.</div>
          </div>
        </div>
      </ng-container>
      <hr style="margin: 0px -24px" />
      <div class="flex-row" style="justify-content: flex-end; gap: 8px">
        <ag-button-v2 (click)="cancelDialog()" type="tertiary" label="Cancel"></ag-button-v2>
        <ag-button-v2 (click)="insertDynamicVariable()" [disabled]="insertClickable() ? false : true" [type]="insertClickable() ? 'primary' : 'secondary'" label="Insert"></ag-button-v2>
      </div>
    </div>
  </ng-container>
</div>
