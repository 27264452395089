import { Component, HostBinding, Input } from "@angular/core"
@Component({
  selector: "ag-button-v2",
  templateUrl: "./ag-button-v2.component.html",
  styleUrls: ["./ag-button-v2.component.scss"],
})
export class AgButtonV2Component {
  @HostBinding("style.pointer-events") get pEvents(): string {
    return this.disabled ? "none" : "auto"
  }
  @Input() public blackImg: boolean //if you want a white image this needs to be set to true.
  @Input() public label: string
  @Input() public labelColor: string
  @Input() public imagePath: string
  @Input() public imagePlacement: ImgPlacement = "right"
  @Input() public disabled: boolean
  @Input() public size: AgSize = "medium"
  @Input() public state: AgState = "active"
  @Input() public type: ButtonVersion = "primary"
  @Input() public loading: boolean = false
  @Input() public isLoadable: boolean = false
  @Input() public specificWidth: string
  @Input() public imgSize: string
  @Input() public destructive: boolean = false

  constructor() {}
}

export type ImgPlacement = "left" | "right"
export type ButtonVersion = "primary" | "secondary" | "tertiary"
export type AgSize = "large" | "medium" | "small"
export type AgState = "active" | "hover" | "pressed" | "inactive"
