<div style="height: fit-content; max-height: 100%; width: 100%; position: relative" [hidden]="!showBox ? showBoxClicked() : false">
  <div [ngClass]="!useFixedPosition ? 'container-standard' : 'container-fixed'">
    <div class="form-div" *ngIf="showSearchField">
      <div class="search-bar">
        <input class="search-bar-input" #inputField placeholder="Search" (input)="onInputChange(inputField.value)" />
        <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/magnifier.svg" class="form-img" />
      </div>
    </div>
    <div class="line" *ngIf="chooseMultiple"></div>
    <div class="hovering-item">
      <div class="item" (click)="onSelectAllClicked()" *ngIf="chooseMultiple">
        <label class="item-text disable-selection">
          <ng-container *ngIf="selectedSelectAll">
            <img style="padding-right: 12px; pointer-events: none" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons/CheckedBox.svg" />
          </ng-container>
          <ng-container *ngIf="!selectedSelectAll">
            <img style="padding-right: 12px; pointer-events: none" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons/Box.svg" />
          </ng-container>
          Select all
        </label>
      </div>
    </div>
    <div class="line" *ngIf="chooseMultiple"></div>
    <div class="top-bottom-padding" [ngStyle]="{ 'max-height': maxHeight - 43 + 'px', 'padding-top': chooseMultiple ? '0' : '8px' }">
      <div [hidden]="list.length < 1">
        <label class="header" [hidden]="isTyping" *ngIf="showHeaders">ALPHABETICAL</label>
        <div class="spacer" *ngIf="showHeaders"></div>
        <div class="items-container">
          <div *ngFor="let item of displayedList">
            <div class="hovering-item">
              <div class="item" (click)="onItemClicked(item)">
                <label class="item-text-left disable-selection">
                  {{ item.label }}
                </label>
                <ng-container *ngIf="checkSelectedItems(item)">
                  <img style="height: 16px; width: 16px; pointer-events: none" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/check.svg" />
                </ng-container>
              </div>
            </div>
          </div>
          <div class="no-result-text" *ngIf="displayedList.length === 0">No results</div>
        </div>
      </div>
    </div>
  </div>
</div>
