<div class="additional-charges-div" [ngStyle]="{ 'min-width': minWidth + 'px', 'max-width': maxWidth + 'px' }">
  <label
    [ngClass]="!hideSearchBox ? 'activeSearchBox' : ''"
    class="additional-charges-form ag-size-{{ size }}"
    [ngClass]="!disable ? 'additional-charges-clickable' : 'additional-charges-disabled'"
    [attr.disabled]="disable ? '' : null"
    [ngStyle]="{ border: error ? '1px solid #E55E5E' : '', 'max-height': maxHeightNotClicked === 0 ? '' : maxHeightNotClicked + 'px' }"
    (click)="onFieldClicked()">
    <div class="title-container" *ngIf="size === 'large'">
      <span *ngIf="dropDownTitle" class="dropDownTitle" [ngStyle]="{ 'line-height': currentValue?.label.length > 0 || currentValueMultiple ? '16px' : '24px', 'font-size': currentValue?.label.length > 0 || currentValueMultiple ? '12px' : '14px' }">{{ dropDownTitle }}</span>
      <div style="overflow: hidden; text-overflow: ellipsis; width: 95%" *ngIf="multipleOutput === false && hasIcons && staticIcon === ''"><img src="{{ currentValue.value }}" /></div>
      <div style="overflow: hidden; text-overflow: ellipsis; width: 95%" *ngIf="multipleOutput === false && !hasIcons && currentValue && staticIcon === ''">{{ currentValue.label }}</div>
      <div *ngIf="(!currentValue || !currentValueMultiple) && !staticIcon">{{ hint }}</div>
    </div>

    <div *ngIf="size === 'small'">
      <div style="overflow: hidden; text-overflow: ellipsis; width: 95%" *ngIf="multipleOutput === false && hasIcons && staticIcon === ''"><img src="{{ currentValue.value }}" /></div>
      <div style="overflow: hidden; text-overflow: ellipsis; width: 95%" *ngIf="multipleOutput === false && !hasIcons && currentValue && staticIcon === ''">{{ currentValue.label }}</div>
      <div *ngIf="(!currentValue || !currentValueMultiple) && !staticIcon">{{ hint }}</div>
      <div *ngIf="multipleOutput === false && staticIcon !== ''"><img src="{{ staticIcon }}" /></div>
    </div>
    <div style="overflow: hidden; text-overflow: ellipsis; width: 95%" *ngIf="multipleOutput">
      <ul style="list-style: none; display: flex; flex-wrap: nowrap">
        <ng-container *ngFor="let item of currentValueMultiple; let i = index">
          <ng-container *ngIf="i > 0">, &nbsp;</ng-container>
          <li style="flex-basis: 20%">{{ item.label }}</li>
        </ng-container>
      </ul>
    </div>
    <img src="/assets/shared/dropdown_large.svg" class="code-changeable-img disable-selection" [ngClass]="!disable ? 'additional-charges-clickable' : 'additional-charges-disabled'" #arrowImage />
  </label>
  <div class="search-box-wrapper">
    <ng-container *ngIf="type === 'text-single'">
      <ag-single-box
        [currentValue]="currentValue"
        [showSearchField]="showSearchField"
        [maxHeight]="maxHeight"
        [showBox]="!hideSearchBox"
        [showHeaders]="false"
        [list]="list"
        [useFixedPosition]="useFixedPosition"
        (output)="onOutput($event)"
        (searchBoxFieldClicked)="onSearchBoxFieldClicked($event)"></ag-single-box>
    </ng-container>
    <ng-container *ngIf="type === 'text-multiple'">
      <ag-multiple-box
        [currentValueMultiple]="currentValueMultiple"
        [showSearchField]="showSearchField"
        [maxHeight]="maxHeight"
        [showBox]="!hideSearchBox"
        [showHeaders]="false"
        [list]="list"
        [useFixedPosition]="useFixedPosition"
        (outputMultiple)="onOutputMultiple($event)"
        (searchBoxFieldClicked)="onSearchBoxFieldClicked($event)"></ag-multiple-box>
    </ng-container>
    <ng-container *ngIf="type === 'country-code'">
      <ag-country-code-box
        [currentValue]="currentValue"
        [showSearchField]="showSearchField"
        [maxHeight]="maxHeight"
        [showBox]="!hideSearchBox"
        [showHeaders]="false"
        [useFixedPosition]="useFixedPosition"
        (output)="onOutput($event)"
        (searchBoxFieldClicked)="onSearchBoxFieldClicked($event)"></ag-country-code-box>
    </ng-container>
    <ng-container *ngIf="type === 'right-checkMark'">
      <ag-right-checkMark-box
        [currentValueMultiple]="currentValueMultiple"
        [showSearchField]="showSearchField"
        [maxHeight]="maxHeight"
        [showBox]="!hideSearchBox"
        [showHeaders]="false"
        [list]="list"
        [chooseMultiple]="chooseMultipleCheckmark"
        [useFixedPosition]="useFixedPosition"
        (outputMultiple)="onOutputMultiple($event)"
        (outputSingle)="onOutput($event)"
        (searchBoxFieldClicked)="onSearchBoxFieldClicked($event)"></ag-right-checkMark-box>
    </ng-container>
    <ng-container *ngIf="type === 'text-multiple-section'">
      <ag-multiple-section-box
        [currentValue]="currentValue"
        [maxWidth]="dropDownWidth"
        [disabledInputs]="disabledInputs"
        [hintForDisabledInputs]="hintForDisabledInputs"
        [maxHeight]="maxHeight"
        [showBox]="!hideSearchBox"
        [list]="list"
        [useFixedPosition]="useFixedPosition"
        (output)="unSectionOutput($event)"></ag-multiple-section-box>
    </ng-container>
    <!-- <app-search-box-v3
      [currentValue]="currentValue" [currentValueMultiple]="currentValueMultiple" [showSearchField]="showSearchField" [maxHeight]="maxHeight"
      [showBox]="!hideSearchBox" [showHeaders]="false" [alphabeticalList]="list" [noIconText]="noIconText" [hasIcons]="hasIcons"
      [useFixedPosition]="useFixedPosition"  [multipleOutput]="multipleOutput" [hint]="hint"
      (output)="onOutput($event)" (outputMultiple)="onOutputMultiple($event)" (searchBoxFieldClicked)="onSearchBoxFieldClicked($event)">
    </app-search-box-v3> -->
  </div>
  <div class="drop-down-message" *ngIf="(infoMessage && !errorMessage) || (infoMessage && errorMessage === '')">
    <img class="message-icon" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/i_letter.svg" />
    {{ infoMessage }}
  </div>
  <div class="drop-down-message" style="color: #e55e5e" *ngIf="(!infoMessage && errorMessage) || (infoMessage === '' && errorMessage)">
    <img class="error-icon" src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Info/1.5/cross.svg" />
    {{ errorMessage }}
  </div>
</div>
