<div [ngClass]="(isInputFocused && !disable) || (isInputHovered && !disable) ? 'main-container-focused' : 'main-container'" (mouseenter)="onEnter()" (mouseleave)="onLeave()">
  <div class="tag-container">
    <ng-container *ngIf="tagsList?.length > 0">
      <div class="tag" *ngFor="let tag of tagsList" [ngStyle]="{ 'background-color': tagsBackgroundColor }">
        {{ tag }}
        <img [src]="iconService.Icon.cross" style="height: 12px; width: 12px; cursor: pointer" (click)="removeTag(tag)" />
      </div>
    </ng-container>
    <input
      type="text"
      class="input"
      [ngClass]="(isInputFocused && !disable) || (isInputHovered && !disable) ? 'input-focused' : 'input'"
      #input
      [disabled]="disable"
      [placeholder]="tagsList?.length > 0 ? '' : placeholder"
      [(ngModel)]="inputValue"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keydown.space)="!noSpace ? addTag() : ''"
      (keydown.enter)="addTag()" />
  </div>
</div>
