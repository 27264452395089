<div class="flex-column" [ngStyle]="{ 'min-width': maxWidth + 'px' }" (click)="labelClick()">
  <div class="flex-row">
    <div class="relative">
      <input
        [ngStyle]="{ 'background-color': blackVersion && checked === 'intdeterminate' ? '#222' : '' }"
        [ngClass]="blackVersion ? 'black-input-filter' : ''"
        [formControl]="control"
        type="checkbox"
        class="ag-checkbox ag-size-{{ size }}"
        [class.has-errors]="control?.invalid && control?.dirty"
        [class.disabled-checkbox]="control?.disabled" />
      <img [ngClass]="blackVersion ? 'white-img-filter' : ''" [class.disabled]="control?.disabled" *ngIf="control?.value && checked !== 'intdeterminate'" class="ag-checkbox-checked-icon-{{ size }}" [src]="iconService.Icon.check_rounded" />
      <img [ngClass]="blackVersion ? 'white-img-filter' : ''" [class.disabled]="control?.disabled" *ngIf="checked === 'intdeterminate'" class="ag-checkbox-checked-icon-{{ size }}" [src]="iconService.Icon.minus" />
    </div>
    <div *ngIf="label" [class.disabled]="control?.disabled" class="checkbox-label">{{ label }}</div>
  </div>
  <div class="checkbox-subtitle" *ngIf="subtitle">{{ subtitle }}</div>
</div>
