import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output } from "@angular/core"
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog"
import { CommunicationApiService } from "@api/communication/communication-api.service"
import { CSharpTypeMapper, DynamicVariableList, DynamicVariableResponse, InputValue, Properties } from "@api/communication/models/communication"
import { Function, FunctionInfo } from "@api/communication/models/functions"
import { LabelValue } from "@shared/animations/round-drop-down-list-v3"
import { IconImageService } from "@shared/services/icon-image.service"
import { StateService } from "@shared/services/state.service"
import { ToastServiceV2 } from "@shared/services/toast-v2/toast-v2.service"
import { TemplateService } from "app/communication/messages/template.service"
import { v4 as uuidv4 } from "uuid"

@Component({
  selector: "app-dynamic-variable-dialog",
  templateUrl: "./dynamic-variable-dialog.component.html",
  styleUrls: ["./dynamic-variable-dialog.component.scss"],
})
export class DynamicVariableDialogComponent implements OnInit {
  public searchText: string
  public dynamicVariableList: DynamicVariableResponse[]
  public categoryList: LabelValue[] = []
  public DynamicDataList: DynamicVariableResponse
  public propertiesList: Properties[] = []
  public filteredPropertiesList: Properties[] = []
  public selectedDynamicVariable: Properties
  public selectedVariable: boolean = false
  public hideIfMissing: boolean = false
  public defaultValue: string = ""
  hideDialog: boolean = true

  @Input() communicationMode: boolean = true
  @Input() templateId: string = ""
  @Input() existingFunctions: DynamicVariableList[]
  @Output() toggleDialog = new EventEmitter<boolean>()
  @Output() savedFunctionInfo = new EventEmitter<FunctionInfo>()

  @HostListener("document:click", ["$event"])
  clickedOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target) && !this.hideDialog) {
      this.hideDialog = true
      this.toggleDialog.emit(true)
    } else {
      this.hideDialog = false
    }
  }

  constructor(public toastService: ToastServiceV2, public communicationApiService: CommunicationApiService, public templateService: TemplateService, public stateService: StateService, private eRef: ElementRef, public iconService: IconImageService) {}

  ngOnInit(): void {
    var template = this.communicationMode ? this.templateService.getTemplate() : null
    this.communicationApiService.Communication.GetDynamicVariableList(this.stateService.selectedHotel.Id).subscribe((list) => {
      this.dynamicVariableList = list
      this.DynamicDataList = this.dynamicVariableList.find((x) => x.isRoot === true)
      this.findLowerLayer(this.dynamicVariableList)

      this.propertiesList.sort((a, b) => a.displayName.localeCompare(b.displayName))
      this.filteredPropertiesList = this.propertiesList
      this.filteredPropertiesList = this.filteredPropertiesList.filter((x) => x.name !== "RatePlanCode" && x.name !== "Comments")
      if (template?.templateType === "EMAIL" && this.communicationMode) {
        this.filteredPropertiesList = this.filteredPropertiesList.filter((x) => x.name !== "CheckinLinkSMS" && x.name !== "CheckinLink" && x.name !== "Check in Link")
      } else if (this.communicationMode) {
        this.filteredPropertiesList = this.filteredPropertiesList.filter((x) => x.name !== "CheckinLinkEmail" && x.name !== "CheckinLink" && x.name !== "Check in Link")
      }
      var topLevelNodes: string[] = []
      list.forEach((node) => {
        topLevelNodes.push(node.typeName)
      })
      this.filteredPropertiesList = this.filteredPropertiesList.filter((x) => !topLevelNodes.includes(x.type))
    })
    this.hideDialog = true
  }
  private findLowerLayer(dynamicVariableList: DynamicVariableResponse[]) {
    dynamicVariableList.forEach((dv) => {
      if (dv.isRoot === true) {
        dv.properties.forEach((variable) => {
          if (dv.typeName !== variable.name) {
            variable.context = "None"
            this.propertiesList.push(variable)
          }
        })
      }

      if (dv.properties.length > 0 && !dv.isRoot && this.DynamicDataList.properties.find((x) => x.type === dv.typeName)) {
        dv.properties.forEach((variable) => {
          const dvPrefix = this.DynamicDataList.properties.find((x) => x.type === dv.typeName).name
          variable.context = dvPrefix
          if (this.dynamicVariableList.find((x) => x.typeName === variable.type)) {
            var list = this.dynamicVariableList.find((x) => x.typeName === variable.type)
            var subdvName = dv.properties.find((x) => x.type === list.typeName).name
            list.properties.forEach((listvar) => {
              listvar.context = dvPrefix + ":" + subdvName
            })
            this.propertiesList = [...this.propertiesList, ...list.properties]
          } else {
            this.propertiesList.push(variable)
          }
        })
      }
    })
  }

  public cancelDialog(): void {
    this.hideDialog = true
    this.toggleDialog.emit(true)
  }

  public insertDynamicVariable(): void {
    var chosenString: object = null
    var chosenFallback: object = null

    const dvPrefix = this.selectedDynamicVariable.context
    const dvName = dvPrefix === "None" ? this.selectedDynamicVariable.name : dvPrefix + ":" + this.selectedDynamicVariable.name

    if (this.selectedDynamicVariable.type === "IList<String>") {
      var list = {
        BodyDataType: null,
        BodyType: "dv",
        BodyData: dvName,
        ChildBodies: [],
      }
      var listToString = {
        BodyData: "listOfTextToText",
        BodyDataType: null,
        BodyType: "f",
        ChildBodies: [
          list,
          {
            BodyDataType: "String",
            BodyType: "sv",
            BodyData: "<br>",
            ChildBodies: [],
          },
        ],
      }
      var fallBack = {
        BodyData: "variableWithStringFallback",
        BodyDataType: null,
        BodyType: "f",
        ChildBodies: [
          datetimeToString,
          {
            BodyDataType: "String",
            BodyType: "sv",
            BodyData: this.defaultValue,
            ChildBodies: [],
          },
        ],
      }
      chosenString = listToString
      chosenFallback = fallBack
    } else if (this.selectedDynamicVariable.name === "StartDate" || this.selectedDynamicVariable.name === "EndDate") {
      var date = {
        BodyDataType: null,
        BodyType: "dv",
        BodyData: dvName,
        ChildBodies: [],
      }
      var datetimeToString = {
        BodyData: "dateTimeToString",
        BodyDataType: null,
        BodyType: "f",
        ChildBodies: [
          date,
          {
            BodyDataType: "String",
            BodyType: "sv",
            BodyData: "MMM. dd, yyyy",
            ChildBodies: [],
          },
        ],
      }
      var fallBackWrapper = {
        BodyData: "variableWithStringFallback",
        BodyDataType: null,
        BodyType: "f",
        ChildBodies: [
          datetimeToString,
          {
            BodyDataType: "String",
            BodyType: "sv",
            BodyData: this.defaultValue,
            ChildBodies: [],
          },
        ],
      }
      chosenString = datetimeToString
      chosenFallback = fallBackWrapper
    } else {
      var dynamicVariable = {
        BodyDataType: null,
        BodyType: "dv",
        BodyData: dvName,
        ChildBodies: [],
      }

      var bodyFallback = {
        BodyData: "variableWithStringFallback",
        BodyDataType: null,
        BodyType: "f",
        ChildBodies: [
          dynamicVariable,
          {
            BodyDataType: "String",
            BodyType: "sv",
            BodyData: this.defaultValue,
            ChildBodies: [],
          },
        ],
      }
      chosenString = dynamicVariable
      chosenFallback = bodyFallback
    }

    var func: Function = {
      hotelId: this.stateService.selectedHotel.Id,
      rootId: this.templateId,
      id: uuidv4(),
      description: "",
      name: this.selectedDynamicVariable.name,
      _body: this.hideIfMissing ? JSON.stringify(chosenString) : JSON.stringify(chosenFallback),
    }
    this.saveFunction(func)
  }

  private saveFunction(func: Function): void {
    if (this.existingFunctions) {
      var nameToCheck = `[${this.selectedDynamicVariable.displayName}]`
      if (this.existingFunctions.findIndex((x) => x.placeholder === nameToCheck) !== -1) {
        this.savedFunctionInfo.emit({ functionId: func.id, functionName: this.selectedDynamicVariable.displayName, imageUrl: this.selectedDynamicVariable.imageUrl })
        this.hideDialog = true
        this.toggleDialog.emit(true)
      } else {
        this.communicationApiService.Function.SaveFunction(func).subscribe(
          (x) => {
            this.savedFunctionInfo.emit({ functionId: func.id, functionName: this.selectedDynamicVariable.displayName, imageUrl: this.selectedDynamicVariable.imageUrl })
            this.hideDialog = true
            this.toggleDialog.emit(true)
          },
          (error) => {
            console.log(error)
          }
        )
      }
    } else {
      this.communicationApiService.Function.SaveFunction(func).subscribe(
        (x) => {
          this.savedFunctionInfo.emit({ functionId: func.id, functionName: this.selectedDynamicVariable.displayName, imageUrl: this.selectedDynamicVariable.imageUrl })
          this.hideDialog = true
          this.toggleDialog.emit(true)
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  public onSearchChange(searchString: any): void {
    this.filteredPropertiesList = this.filteredPropertiesList.filter((x) => x.displayName.toLocaleLowerCase().includes(searchString.value.toLocaleLowerCase()))
    if (!searchString.value) {
      this.filteredPropertiesList = this.propertiesList
    }
  }

  public changeDefaultValue(event: any): void {
    this.defaultValue = event.value
  }
  public toggleHideMissingData(event: any): void {
    this.hideIfMissing = event
  }

  public selectVariable(property: Properties): void {
    this.selectedVariable = true
    this.selectedDynamicVariable = property
  }

  public insertClickable(): boolean {
    if (this.hideIfMissing || (!this.hideIfMissing && this.defaultValue)) {
      return true
    }
    return false
  }
}
