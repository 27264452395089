import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-single-box",
  templateUrl: "./ag-single-box.component.html",
  styleUrls: ["./ag-single-box.component.scss"],
})
export class AgSingleBoxComponent implements OnInit {
  @Input() list: LabelValue[] = []
  @Input() disabledInputs: LabelValue[] = []
  @Input() showBox = false
  @Input() showSearchField = true
  @Input() useFixedPosition = false
  @Input() showHeaders: boolean = false
  @Input() maxHeight: number = 1000
  @Input() currentValue: LabelValue
  @Input() checkboxType: checkboxType = "square"

  @Output() output = new EventEmitter<LabelValue>()
  @Output() searchBoxFieldClicked = new EventEmitter<boolean>()

  @ViewChild("inputField", { static: false }) inputField: ElementRef
  @ViewChild("arrowImage") arrowImage

  public displayedList: LabelValue[] = []
  public searchForm: FormGroup
  public isTyping = false

  constructor(private fb: FormBuilder, public iconService: IconImageService) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchInput: [""],
    })
    this.displayedList = this.list
  }

  onInputChange(value: string) {
    if (value === "") {
      this.isTyping = false
      this.displayedList = this.list
    } else {
      this.isTyping = true
      this.displayedList = this.list.filter((x) => x.label.toLowerCase().includes(value.toLowerCase()))
    }
  }

  showBoxClicked() {
    if (!this.isTyping || (this.inputField && this.inputField.nativeElement && this.inputField.nativeElement.value === "")) {
      this.displayedList = this.list
    }
    return true
  }

  onItemClicked(item: LabelValue) {
    const disabledItem = this.disabledInputs.some((disabled) => disabled.label === item.label)
    if (!disabledItem && item.label !== this.currentValue.label) {
      this.currentValue = item
      this.output.emit(item)
      this.showBox = false
    }
  }

  onFieldClicked() {
    this.showBox = false
    this.searchBoxFieldClicked.emit(true)
  }

  isSelected(item: LabelValue): boolean {
    return this.currentValue !== null ?? this.currentValue.label === item.label
  }

  public checkSelectedItems(item: LabelValue): boolean {
    if (this.currentValue.label === item.label) {
      return true
    }
    return false
  }

  isItemDisabled(label: string): boolean {
    const itemDisabled = this.displayedList.some((disabled) => !this.disabledInputs.some((di) => di.label === disabled.label))
    if (itemDisabled) {
      return this.disabledInputs.some((item) => item.label === label)
    }
    return false
  }
}

export interface LabelValue {
  label: string
  value: any
}
export type checkboxType = "square" | "circle"
