import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Renderer2, Self, ViewChild } from "@angular/core"
import { IconImageService } from "@shared/services/icon-image.service"
import { AbstractControl, UntypedFormControl, NgControl, ValidationErrors, Validator } from "@angular/forms"

@Component({
  selector: "ag-textarea-v2",
  templateUrl: "./ag-textarea.component.html",
  styleUrls: ["./ag-textarea.component.scss"],
})
export class AgTextareaV2Component implements OnInit, Validator {
  @ViewChild("textarea") textarea: ElementRef
  @ViewChild("textarea") htmlTextarea: HTMLTextAreaElement

  @Input() placeholder: string = ""
  @Input() hint?: string = ""
  @Input() disable: boolean = false
  @Input() initValue: string = ""
  @Input() showSendButton: boolean = false
  @Input() maxChars: number
  @Input() textareaStyles: TextareaStyles = {
    backgroundColor: "#F2F2F2",
    border: "",
    specificWidth: "314px",
    specificHeight: "24px",
  }
  @Input() info: InputInfo = {
    showInfo: false,
    showChars: false,
    type: "info",
    text: "Info",
  }
  @Output() inputResult = new EventEmitter()
  @Output() sendButtonPushed = new EventEmitter()

  public control: UntypedFormControl
  public message: string = ""
  public isInputFocused: boolean = false
  public isInputHovered: boolean = false
  public chars: number = 0

  constructor(
    @Optional() @Self() private _ngControl: NgControl,
    private renderer: Renderer2,
    public iconService: IconImageService,
  ) {
    if (this._ngControl) {
      this._ngControl.valueAccessor = this
    }
  }

  ngOnInit(): void {
    if (this._ngControl?.control) {
      this.control = this._ngControl.control as UntypedFormControl
    } else {
      this.control = new UntypedFormControl({ value: this.initValue, disabled: this.disable })
    }
    this.control.valueChanges.subscribe((x) => {
      this.inputResult.emit(x)
    })
  }

  writeValue() {}
  registerOnChange() {}
  registerOnTouched() {}

  validate(control: AbstractControl): ValidationErrors | null {
    return this.control.valid ? null : control.errors
  }

  public onFocus() {
    this.isInputFocused = true
  }

  public onBlur() {
    this.isInputFocused = false
  }

  public onEnter() {
    this.isInputHovered = true
  }

  public onLeave() {
    this.isInputHovered = false
  }

  public onInput(event: Event) {
    this.message = (event.target as HTMLTextAreaElement).value
    this.inputResult.emit(this.message)
    this.checkHeight(event)
  }

  public checkHeight(event: Event) {
    this.htmlTextarea = event.target as HTMLTextAreaElement
    this.htmlTextarea.rows = 1
    const lineHeight = 24
    const height = this.htmlTextarea.scrollHeight
    const numberOfLines = Math.floor(height / lineHeight)
    this.htmlTextarea.rows = numberOfLines <= 4 ? numberOfLines : 4
    this.chars = this.htmlTextarea.value.length
  }

  public sendInput() {
    if (this.message.length === 0 || this.message.length < 0) {
      return
    } else {
      this.sendButtonPushed.emit(true)
    }
  }
}

export class TextareaStyles {
  backgroundColor?: string
  border?: string
  specificWidth?: string
  specificHeight?: string
}

export class InputInfo {
  showInfo?: boolean
  showChars?: boolean
  type?: Type
  text?: string
}

export type Type = "info" | "error"
