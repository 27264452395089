import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { ConfirmationDialogComponent } from "./dialogs/confirmation-dialog/confirmation-dialog.component"
import { JourneyDoorUnlockCasesDialogComponent } from "./dialogs/journey-door-unlock-cases-dialog/journey-door-unlock-cases-dialog.component"
import { GlobalSnapDialogComponent } from "./dialogs/global-snap-dialog/global-snap-dialog.component"
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core"
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field"
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete"
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button"
import { MatButtonToggleModule } from "@angular/material/button-toggle"
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox"
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog"
import { MatExpansionModule } from "@angular/material/expansion"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input"
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip"
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu"
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio"
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select"
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle"
import { MatSortModule } from "@angular/material/sort"
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs"
import { MatBadgeModule } from "@angular/material/badge"
import { NgApexchartsModule } from "ng-apexcharts"
import { MAT_MOMENT_DATE_FORMATS, MatMomentDateModule } from "@angular/material-moment-adapter"
import { ToastComponent } from "app/toast/toast.component"
import { CombineFullNamePipe } from "app/shared/pipes/combine-fullname.pipe"
import { SafeHtmlPipe } from "app/shared/pipes/safehtml.pipe"
import { ImageComponent } from "./components/image/image.component"
import { ImageUploadComponent } from "./components/image-upload/image-upload.component"
import { MaterialDateAdapter } from "app/shared/MaterialDateAdapter"
import { HeaderComponent } from "./components/header/header.component"
import { JoyrideModule } from "ngx-joyride"
import { TooltipService } from "app/shared/services/tooltip.service"
import { TooltipDirective } from "app/shared/components/tooltip/tooltip.directive"
import { TooltipContentComponent } from "app/shared/components/tooltip/tooltip.component"
import { TooltipContainerComponent } from "app/shared/components/tooltip/tooltip-container.component"
import { QuickActionService } from "./services/quick-action.service"
import { QuickActionDirective } from "./components/quick-action/quick-action.directive"
import { QuickActionComponent } from "./components/quick-action/quick-action.component"
import { QuickActionContainerComponent } from "./components/quick-action/quick-action-container.component"
import { ImageWithCameraComponent } from "./components/image-with-camera/image-with-camera.component"
import { ListItemImageComponent } from "./components/list-item-image/list-item-image.component"
import { ImageUploadHoverComponent } from "./components/image-upload-hover/image-upload-hover.component"
import { QuillModule } from "ngx-quill"
import { ColorPickerModule } from "ngx-color-picker"
import { ConcatenateStringArrayPipe } from "app/shared/pipes/concatenate-string-array.pipe"
import { DonutGraphComponent } from "app/shared/components/graph/donut-graph/donut-graph.component"
import { RefundDialogComponent } from "./dialogs/refund-dialog/refund-dialog.component"
import { ChargeDialogComponent } from "./dialogs/charge-dialog/charge-dialog.component"
import { ChargeBillDialogComponent } from "./dialogs/charge-bill-dialog/charge-bill-dialog.component"
import { SmoothHeightComponent } from "./animations/smooth-height.component"
import { DeletableItemListComponent } from "./animations/deletable-item-list.component"
import { CurrencyFieldComponent } from "./animations/currency-field.component"
import { OriginalCurrencyFieldComponent } from "./animations/original-currency-field.component"
import { SearchBoxComponent } from "./animations/search-box.component"
import { GlobalLoaderComponent } from "./animations/global-loader.component"
import { DropDownListComponent } from "./animations/drop-down-list"
import { RoundDropDownListComponent } from "./animations/round-drop-down-list"
import { RoundButtonComponent } from "./components/round-button"
import { ImageBrowserComponent } from "./animations/image-browser.component"
import { ImageBrowser2Component } from "./animations/image-browser-2.component"
import { DragDropModule } from "@angular/cdk/drag-drop"
import { DragDropImageDirective } from "./animations/drag-drop-image.directive"
import { DragDropImageDirectiveV2 } from "./animations/drag-drop-image.directivev2"
import { NavMenuHotelsListDropdownComponent } from "./animations/list-dropdown-animation"
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap"
import { Graph2Component } from "./components/graph/graph2/graph2.component"
import { AnimatedMenuItemComponent } from "./animations/animated-menu-item"
import { NgxSliderModule } from "@angular-slider/ngx-slider"
import { PaymentRulesService } from "app/property-setup/revenue/payment-rules/payment-rules.service"
import { SaveButton2Component } from "./animations/save-button2.component"
import { D3fcWebglChartComponent } from "./components/graph/webgl-chart/d3fc-webgl-chart/d3fc-webgl-chart.component"
import { NgCircleProgressModule } from "ng-circle-progress"
import { ColumnTemplateComponent } from "./components/column-template/column-template.component"
import { BasicSetupService } from "app/property-setup/revenue/basic-setup/basic-setup.service"
import { MobileTemplateComponent } from "./components/mobile-template/mobile-template.component"
import { PairFieldComponent } from "./components/pair-field/pair-field/pair-field.component"
import { ChooseRoomService } from "app/property-setup/revenue/choose-room/choose-room.service"
import { JourneyProgressWheelComponent } from "./components/journey-progress-wheel/journey-progress-wheel.component"
import { ChargePreAuthDialogComponent } from "./dialogs/charge-pre-auth-dialog/charge-pre-auth-dialog.component"
import { ReleaseDialogComponent } from "./dialogs/release-dialog/release-dialog.component"
import { LabeledTextFieldComponent } from "./animations/labeled-text-field.component"
import { DotLoaderWhiteAnimationComponent } from "./animations/dot-loader-white-animation"
import { LottieModule } from "ngx-lottie"
import player from "lottie-web"
import { PaymentMethodIconComponent } from "./components/payment-method-icon/payment-method-icon.component"
import { AgSectionComponent } from "./layout/ag-section/ag-section.component"
import { AgSectionV2Component } from "./layout/ag-section/ag-sectionv2.component"
import { OverlayFullscreenBoxComponent } from "./layout/overlay-fullscreen-box/overlay-fullscreen-box.component"
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table"
import { AgTextSwitchComponent } from "./components/form-controls/ag-text-switch/ag-text-switch.component"
import { AgInputComponent } from "./components/form-controls/ag-input/ag-input.component"
import { AgInputV2Component } from "./components/form-controls/ag-input-v2/ag-input-v2.component"
import { AgInputCurrencyComponent } from "./components/form-controls/ag-input-currency/ag-input-currency.component"
import { AgInputCurrencyV2Component } from "./components/form-controls/ag-input-currency-v2/ag-input-currency-v2.component"
import { AgTooltipComponent } from "./components/form-controls/ag-tooltip/ag-tooltip.component"
import { AgTooltipDirective } from "./components/form-controls/ag-tooltip/ag-tooltip.directive"
import { RoomServiceComponent } from "app/room-service/room-service.component"
import { AgTimeComponent } from "./components/form-controls/ag-time/ag-time.component"
import { AgOpeningHoursComponent } from "./components/form-controls/ag-opening-hours/ag-opening-hours.component"
import { AgRadioGroupComponent } from "./components/form-controls/ag-radio-group/ag-radio-group.component"
import { AgSelectComponent } from "./components/form-controls/ag-select/ag-select.component"
import { CurrencyService } from "./services/currency.service"
import { AgReportComponent } from "./components/ag-report/ag-report.component"
import { AgReportMessageComponent } from "./components/ag-report/ag-report-message/ag-report-message.component"
import { AgTextareaComponent } from "./components/form-controls/ag-textarea/ag-textarea.component"
import { AgInputPercentageComponent } from "./components/form-controls/ag-input-percentage/ag-input-percentage.component"
import { StateService } from "./services/state.service"
import { AgButtonComponent } from "./components/ag-button/ag-button.component"
import { AgSignatureComponent } from "./components/form-controls/ag-signature/ag-signature.component"
import { AgCheckboxComponent } from "./components/form-controls/ag-checkbox/ag-checkbox.component"
import { AddressService } from "./services/address.service"
import { AgTreeModule } from "./layout/ag-tree/ag-tree.module"
import { AgTreeV2Module } from "./layout/ag-tree-v2/ag-tree-v2.module"
import { AgMobileShutterButtonComponent } from "./animations/ag-mobile-shutter-button.component"
import { AgDurationComponent } from "./components/form-controls/ag-duration/ag-duration.component"
import { SpecialDeliveryFeeDialogComponent } from "./dialogs/special-delivery-fee-dialog/special-delivery-fee-dialog/special-delivery-fee-dialog.component"
import { AgButtonSliderComponent } from "./animations/ag-button-slider-component"
import { DataDisplayIconComponent } from "./components/data-display-icon/data-display-icon.component"
import { SortDirectBookingsItemsPipe } from "./pipes/sort-direct-bookings-items.pipe"
import { SearchBoxV2Component } from "./animations/search-box-v2.component"
import { RoundDropDownListV2Component } from "./animations/round-drop-down-list-v2"
import { AscDescIconComponent } from "./components/asc-desc-icon/asc-desc-icon.component"
import { AgButtonV2Component } from "./components/ag-button-v2/ag-button-v2.component"
import { AgLabelComponent } from "./components/ag-label/ag-label.component"
import { ImageBrowser3Component } from "./animations/image-browser-3.component"
import { NewInfoboxComponent } from "./components/new-infobox/new-infobox.component"
import { FixedBottomBarComponent } from "./components/fixed-bottom-bar/fixed-bottom-bar.component"
import { ToastV2Component } from "./services/toast-v2/toast-v2.component"
import { AgBreadcrumbComponent } from "./components/ag-breadcrumb/ag-breadcrumb.component"
import { AgCheckboxV2Component } from "./components/ag-checkbox-v2/ag-checkbox-v2.component"
import { RoundDropDownListV3Component } from "./animations/round-drop-down-list-v3"
import { SearchBoxV3Component } from "./animations/search-box-v3.component"
import { DatePickerComponent } from "./animations/datePicker-component"
import { CountryCodePickerComponent } from "./animations/country-code-picker-component"
import { DragDropService } from "./services/drag-drop.service"
import { FilterCountryCodes } from "./pipes/search-country-codes.pipe"
import { ConfirmDialogComponent } from "./dialogs/confirm-dialog/confirm-dialog.component"
import { AgToggleSwitchComponent } from "./components/ag-toggle-switch/ag-toggle-switch.component"
import { IconImageService } from "./services/icon-image.service"
import { AgTextareaV2Component } from "./components/ag-textarea/ag-textarea.component"
import { AgInputFieldComponent } from "./components/ag-input-field/ag-input-field.component"
import { AgTooltipV2Component } from "./components/ag-tooltip-v2/ag-tooltip-v2.component"
import { AgTooltipDirectiveV2 } from "./components/ag-tooltip-v2/ag-tooltip-v2.directive"
import { ClickOutsideDirective } from "./directives/clickOutside.directive"
import { AgTooltipContainerV2Component } from "./components/ag-tooltip-v2/ag-tooltip-v2-container.component"
import { AgSearchBarComponent } from "./components/ag-search-bar/ag-search-bar.component"
import { AgTagsInputComponent } from "./components/ag-tags-input/ag-tags-input.component"
import { AgCountryCodeBoxComponent } from "./components/ag-drop-down/ag-country-code-box/ag-country-code-box.component"
import { AgMultipleBoxComponent } from "./components/ag-drop-down/ag-multiple-box/ag-multiple-box.component"
import { AgSingleBoxComponent } from "./components/ag-drop-down/ag-single-box/ag-single-box.component"
import { AgDropDownComponent } from "./components/ag-drop-down/ag-drop-down.component"
import { AgRightCheckMarkBoxComponent } from "./components/ag-drop-down/ag-right-checkmark-box/ag-right-checkmark-box.component"
import { AgFilterDropDownComponent } from "./components/ag-filter-drop-down/ag-filter-drop-down.component"
import { MediaLibraryDragImageDirective } from "./animations/media-library-drag-image.directive"
import { AgToggleButtonComponent } from "./components/ag-toggle-button/ag-toggle-button.component"
import { AgMultipleSectionBoxComponent } from "./components/ag-drop-down/ag-multiple-section-box/ag-multiple-section-box.component"
import { AgConfirmDialogComponent } from "./dialogs/ag-confirm-dialog/ag-confirm-dialog.component"

export function playerFactory() {
  return player
}

window["$"] = $
window["jQuery"] = $

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    JourneyDoorUnlockCasesDialogComponent,
    GlobalSnapDialogComponent,
    ToastComponent,
    SafeHtmlPipe,
    CombineFullNamePipe,
    ImageComponent,
    ImageWithCameraComponent,
    ListItemImageComponent,
    ImageUploadComponent,
    HeaderComponent,
    TooltipDirective,
    TooltipContentComponent,
    TooltipContainerComponent,
    QuickActionDirective,
    QuickActionComponent,
    QuickActionContainerComponent,
    ImageUploadHoverComponent,
    ConcatenateStringArrayPipe,
    RefundDialogComponent,
    ChargeDialogComponent,
    ChargeBillDialogComponent,
    DonutGraphComponent,
    Graph2Component,
    SmoothHeightComponent,
    NavMenuHotelsListDropdownComponent,
    DeletableItemListComponent,
    CurrencyFieldComponent,
    OriginalCurrencyFieldComponent,
    SearchBoxComponent,
    SearchBoxV2Component,
    SearchBoxV3Component,
    GlobalLoaderComponent,
    DropDownListComponent,
    RoundDropDownListComponent,
    RoundDropDownListV2Component,
    RoundDropDownListV3Component,
    RoundButtonComponent,
    ImageBrowserComponent,
    ImageBrowser2Component,
    ImageBrowser3Component,
    DragDropImageDirective,
    DragDropImageDirectiveV2,
    MediaLibraryDragImageDirective,
    AnimatedMenuItemComponent,
    SaveButton2Component,
    D3fcWebglChartComponent,
    ColumnTemplateComponent,
    MobileTemplateComponent,
    PairFieldComponent,
    JourneyProgressWheelComponent,
    ChargePreAuthDialogComponent,
    LabeledTextFieldComponent,
    ReleaseDialogComponent,
    ConfirmDialogComponent,
    DotLoaderWhiteAnimationComponent,
    PaymentMethodIconComponent,
    AgSectionComponent,
    AgSectionV2Component,
    DataDisplayIconComponent,
    OverlayFullscreenBoxComponent,
    AgTextSwitchComponent,
    AgInputComponent,
    AgDurationComponent,
    AgInputCurrencyComponent,
    AgInputCurrencyV2Component,
    AgTooltipComponent,
    AgTooltipDirective,
    RoomServiceComponent,
    AgTimeComponent,
    AgOpeningHoursComponent,
    AgRadioGroupComponent,
    AgSelectComponent,
    AgReportComponent,
    AgReportMessageComponent,
    AgTextareaComponent,
    AgInputPercentageComponent,
    AgButtonComponent,
    AgButtonV2Component,
    AgLabelComponent,
    AgSignatureComponent,
    AgCheckboxComponent,
    AgMobileShutterButtonComponent,
    SpecialDeliveryFeeDialogComponent,
    AgButtonSliderComponent,
    SortDirectBookingsItemsPipe,
    AscDescIconComponent,
    NewInfoboxComponent,
    FixedBottomBarComponent,
    AgInputV2Component,
    ToastV2Component,
    AgBreadcrumbComponent,
    AgCheckboxV2Component,
    DatePickerComponent,
    CountryCodePickerComponent,
    FilterCountryCodes,
    AgToggleSwitchComponent,
    AgToggleButtonComponent,
    AgTextareaV2Component,
    AgInputFieldComponent,
    AgTooltipDirectiveV2,
    ClickOutsideDirective,
    AgTooltipV2Component,
    AgTooltipContainerV2Component,
    AgSearchBarComponent,
    AgCountryCodeBoxComponent,
    AgMultipleBoxComponent,
    AgSingleBoxComponent,
    AgMultipleSectionBoxComponent,
    AgDropDownComponent,
    AgRightCheckMarkBoxComponent,
    AgFilterDropDownComponent,
    AgTagsInputComponent,
    AgConfirmDialogComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ColorPickerModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatPaginatorModule,
    MatTabsModule,
    MatMenuModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatSortModule,
    MatTableModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatMomentDateModule,
    NgApexchartsModule,
    RouterModule,
    DragDropModule,
    QuillModule,
    JoyrideModule.forRoot(),
    NgbTooltipModule,
    NgxSliderModule,
    AgTreeModule,
    AgTreeV2Module,
    LottieModule.forRoot({ player: playerFactory }),
    NgCircleProgressModule.forRoot({
      radius: 15,
      showUnits: false,
      showTitle: false,
      showSubtitle: false,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 0,
      animation: false,
      space: -4,
      lazy: false,
    }),
  ],
  exports: [
    CommonModule,
    ColorPickerModule,
    ConcatenateStringArrayPipe,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatButtonModule,
    SafeHtmlPipe,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTabsModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTableModule,
    NgApexchartsModule,
    ToastComponent,
    ToastV2Component,
    CombineFullNamePipe,
    ImageComponent,
    ImageWithCameraComponent,
    ListItemImageComponent,
    ImageUploadComponent,
    HeaderComponent,
    TooltipDirective,
    TooltipContentComponent,
    TooltipContainerComponent,
    AgTooltipDirectiveV2,
    ClickOutsideDirective,
    AgTooltipV2Component,
    AgTooltipContainerV2Component,
    QuickActionDirective,
    QuickActionComponent,
    QuickActionContainerComponent,
    MatSortModule,
    JoyrideModule,
    ImageUploadHoverComponent,
    ImageUploadHoverComponent,
    DonutGraphComponent,
    Graph2Component,
    QuillModule,
    SmoothHeightComponent,
    NavMenuHotelsListDropdownComponent,
    DeletableItemListComponent,
    CurrencyFieldComponent,
    OriginalCurrencyFieldComponent,
    SearchBoxComponent,
    SearchBoxV2Component,
    SearchBoxV3Component,
    GlobalLoaderComponent,
    DropDownListComponent,
    RoundDropDownListComponent,
    RoundDropDownListV2Component,
    RoundDropDownListV3Component,
    RoundButtonComponent,
    ImageBrowserComponent,
    ImageBrowser2Component,
    ImageBrowser3Component,
    DragDropModule,
    DragDropImageDirective,
    DragDropImageDirectiveV2,
    MediaLibraryDragImageDirective,
    AnimatedMenuItemComponent,
    SaveButton2Component,
    D3fcWebglChartComponent,
    NgCircleProgressModule,
    ColumnTemplateComponent,
    MobileTemplateComponent,
    PairFieldComponent,
    JourneyProgressWheelComponent,
    LabeledTextFieldComponent,
    DotLoaderWhiteAnimationComponent,
    PaymentMethodIconComponent,
    AgSectionComponent,
    AgSectionV2Component,
    DataDisplayIconComponent,
    AgButtonSliderComponent,
    OverlayFullscreenBoxComponent,
    AgTextSwitchComponent,
    AgInputComponent,
    AgInputV2Component,
    AgDurationComponent,
    AgInputCurrencyComponent,
    AgInputCurrencyV2Component,
    RoomServiceComponent,
    AgTimeComponent,
    AgOpeningHoursComponent,
    AgRadioGroupComponent,
    AgSelectComponent,
    AgReportComponent,
    AgReportMessageComponent,
    AgTextareaComponent,
    AgInputPercentageComponent,
    AgButtonComponent,
    AgButtonV2Component,
    AgLabelComponent,
    AgSignatureComponent,
    AgCheckboxComponent,
    AgTreeModule,
    AgTreeV2Module,
    AgMobileShutterButtonComponent,
    SortDirectBookingsItemsPipe,
    AscDescIconComponent,
    NewInfoboxComponent,
    FixedBottomBarComponent,
    AgBreadcrumbComponent,
    AgCheckboxV2Component,
    DatePickerComponent,
    CountryCodePickerComponent,
    FilterCountryCodes,
    AgToggleSwitchComponent,
    AgToggleButtonComponent,
    AgTextareaV2Component,
    AgInputFieldComponent,
    AgSearchBarComponent,
    AgCountryCodeBoxComponent,
    AgMultipleBoxComponent,
    AgSingleBoxComponent,
    AgMultipleSectionBoxComponent,
    AgDropDownComponent,
    AgRightCheckMarkBoxComponent,
    AgFilterDropDownComponent,
    AgTagsInputComponent,
    AgConfirmDialogComponent,
  ],
  providers: [
    QuickActionService,
    TooltipService,
    ConcatenateStringArrayPipe,
    CombineFullNamePipe,
    BasicSetupService,
    PaymentRulesService,
    ChooseRoomService,
    CurrencyService,
    AgTooltipDirective,
    StateService,
    AddressService,
    DragDropService,
    IconImageService,
    FilterCountryCodes,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    {
      provide: DateAdapter,
      useClass: MaterialDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
