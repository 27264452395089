import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-toggle-switch",
  templateUrl: "./ag-toggle-switch.component.html",
  styleUrls: ["./ag-toggle-switch.component.scss"],
})
export class AgToggleSwitchComponent implements OnInit, OnChanges {
  @Output() public toggleCheck = new EventEmitter()

  @Input() initValue = false

  public CheckBoxValue

  constructor(public iconService: IconImageService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initValue.currentValue !== this.CheckBoxValue) {
      this.CheckBoxValue = changes.initValue.currentValue
    }
  }
  ngOnInit(): void {
    this.CheckBoxValue = this.initValue
  }

  toggleCheckBox(event: any) {
    this.CheckBoxValue = !this.CheckBoxValue
    this.toggleCheck.emit(event.target.checked)
  }
}
