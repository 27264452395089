import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { LabelValue } from "../ag-drop-down.component"
import { FormBuilder, FormGroup } from "@angular/forms"

@Component({
  selector: "ag-multiple-box",
  templateUrl: "./ag-multiple-box.component.html",
  styleUrls: ["./ag-multiple-box.component.scss"],
})
export class AgMultipleBoxComponent implements OnInit {
  @Input() list: LabelValue[] = []
  @Input() showBox = false
  @Input() showSearchField = true
  @Input() useFixedPosition = false
  @Input() showHeaders: boolean = false
  @Input() maxHeight: number = 1000
  @Input() currentValueMultiple: LabelValue[]

  @Output() outputMultiple = new EventEmitter<LabelValue[]>()
  @Output() searchBoxFieldClicked = new EventEmitter<boolean>()

  @ViewChild("inputField", { static: false }) inputField: ElementRef
  @ViewChild("arrowImage") arrowImage

  public displayedList: LabelValue[] = []
  public searchForm: FormGroup
  public isTyping = false
  public selectedSelectAll: boolean = false

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchInput: [""],
    })
    if (!this.currentValueMultiple) {
      this.currentValueMultiple = []
    }
    this.displayedList = this.list
  }

  onInputChange(value: string) {
    if (value === "") {
      this.isTyping = false
      this.displayedList = this.list
    } else {
      this.isTyping = true
      this.displayedList = this.list.filter((x) => x.label.toLowerCase().includes(value.toLowerCase()))
    }
  }

  showBoxClicked() {
    if (!this.isTyping || (this.inputField && this.inputField.nativeElement && this.inputField.nativeElement.value === "")) {
      this.displayedList = this.list
    }
    return true
  }

  onFieldClicked() {
    this.showBox = false
    this.searchBoxFieldClicked.emit(true)
  }

  onItemClicked(value: LabelValue) {
    if (this.currentValueMultiple.includes(value)) {
      this.currentValueMultiple = this.currentValueMultiple.filter((item) => item !== value)
      this.selectedSelectAll = false
    } else {
      this.currentValueMultiple.push(value)
      this.selectedSelectAll = false
    }
    this.currentValueMultiple.length === this.displayedList.length ? (this.selectedSelectAll = true) : (this.selectedSelectAll = false)
    this.outputMultiple.emit(this.currentValueMultiple)
  }

  onSelectAllClicked() {
    if (this.currentValueMultiple === this.list) {
      this.currentValueMultiple = []
      this.selectedSelectAll = false
    } else {
      this.currentValueMultiple = this.list
      this.selectedSelectAll = true
    }
  }

  public checkSelectedItems(item: LabelValue): boolean {
    if (this.currentValueMultiple.find((selected) => selected?.label === item?.label)) {
      return true
    }
    return false
  }
}
