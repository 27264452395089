<div class="textarea-container" [ngStyles]="{ width: textareaStyles?.specificWidth ? textareaStyles?.specificWidth : '' }">
  <div
    [ngClass]="isInputFocused || (isInputHovered && !disable) ? 'textarea-focused' : 'textarea'"
    (mouseenter)="onEnter()"
    (mouseleave)="onLeave()"
    [ngStyle]="{
      'align-items': htmlTextarea?.offsetHeight > 28 ? 'flex-end' : 'center',
      opacity: disable ? 0.5 : 1,
      border: !isInputFocused && !isInputHovered && info?.type === 'error' && !disable ? '1px solid #E55E5E' : textareaStyles?.border,
      backgroundColor: !isInputFocused && !isInputHovered && textareaStyles?.backgroundColor && !disable ? textareaStyles?.backgroundColor : ''
    }">
    <textarea
      [ngClass]="isInputFocused || (isInputHovered && !disable) ? 'input-focused' : 'input'"
      #textarea
      contenteditable="true"
      rows="1"
      [disabled]="disable"
      [formControl]="control"
      [value]="initValue"
      [placeholder]="placeholder"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (input)="onInput($event)"
      [ngStyle]="{
        backgroundColor: !isInputFocused && !isInputHovered && textareaStyles?.backgroundColor && !disable ? textareaStyles?.backgroundColor : '',
        'min-height': textareaStyles?.specificHeight ? textareaStyles?.specificHeight : ''
      }"></textarea>
    <img *ngIf="showSendButton && info?.type !== 'error'" class="sendIcon" [src]="iconService?.Icon.send" [ngStyle]="{ opacity: message?.length > 0 ? '1' : '0.5', cursor: message?.length > 0 ? 'pointer' : 'default' }" (click)="sendInput()" />

    <img *ngIf="showSendButton && info?.type === 'error'" class="sendIconError" [src]="iconService.Icon.send" [ngStyle]="{ opacity: '0.5' }" />
  </div>
  <div class="bottom-container">
    <div class="info-container" *ngIf="info?.showInfo">
      <img class="info-img" [src]="iconService?.Icon.cross_1_5" *ngIf="info?.type === 'info'" />
      <img class="error-img" [src]="iconService?.Icon.cross_1_5" *ngIf="info?.type === 'error'" />
      <div class="info-text" [ngStyle]="{ color: info?.type === 'error' ? '#E55E5E' : '#222222' }">
        {{ info?.text }}
      </div>
    </div>
    <div class="chars" *ngIf="info?.showChars && maxChars">{{ chars }}/{{ maxChars }}</div>
  </div>
</div>
