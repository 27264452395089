import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges, ViewChild } from "@angular/core"
import { AbstractControl, UntypedFormControl, NgControl, ValidationErrors } from "@angular/forms"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-search-bar",
  templateUrl: "./ag-search-bar.component.html",
  styleUrls: ["./ag-search-bar.component.scss"],
})
export class AgSearchBarComponent implements OnInit, OnChanges {
  @ViewChild("input") input: ElementRef

  @Input() label: string = "" //label is only used for size big
  @Input() placeholder: string = ""
  @Input() disable: boolean = false
  @Input() initValue: string = ""
  @Input() size: Size = "Big"

  @Input() styles: InputStyles = {
    labelColor: "",
    backgroundColor: "",
    border: "",
    specificWidth: "314px",
  }

  @Output() searchResult = new EventEmitter()

  public control: UntypedFormControl
  public isInputFocused: boolean = false
  public isInputHovered: boolean = false
  public labelClicked: boolean = false
  public searchInput: string = ""

  constructor(@Optional() @Self() private _ngControl: NgControl, public iconService: IconImageService) {
    if (this._ngControl) {
      this._ngControl.valueAccessor = this
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disable.currentValue) {
      this.control.disable()
    } else if (changes.disable.currentValue === false) {
      this.control?.enable()
    }
  }

  ngOnInit(): void {
    if (this.initValue.length > 0) {
      this.labelClicked = true
      this.searchInput = this.initValue
    }
    if (this._ngControl?.control) {
      this.control = this._ngControl.control as UntypedFormControl
    } else {
      this.control = new UntypedFormControl({ value: this.initValue, disabled: this.disable })
    }
    this.control.valueChanges.subscribe((x) => {
      this.searchResult.emit(x)
    })
  }

  writeValue() {}
  registerOnChange() {}
  registerOnTouched() {}

  validate(control: AbstractControl): ValidationErrors | null {
    return this.control.valid ? null : control.errors
  }

  public onFocus() {
    this.isInputFocused = true
    this.input.nativeElement.select()
  }

  public onBlur() {
    this.isInputFocused = false
    this.labelClicked = false
  }

  public onEnter() {
    this.isInputHovered = true
  }

  public onLeave() {
    this.isInputHovered = false
  }

  public showPlaceholder(): string {
    if (this.isInputFocused || !this.label) {
      return this.placeholder
    } else {
      return ""
    }
  }

  public clickLabel() {
    this.labelClicked = true
    this.input.nativeElement.focus()
  }

  public onInput(event: Event) {
    this.searchInput = (event.target as HTMLInputElement).value
    this.searchResult.emit(this.searchInput)
  }

  public clearSearch() {
    this.searchInput = ""
    this.input.nativeElement.value = ""
    this.labelClicked = false
    this.isInputFocused = false
    this.searchResult.emit(this.searchInput)
  }
}

export class InputStyles {
  labelColor?: string
  backgroundColor?: string
  border?: string
  specificWidth?: string
}

export type Size = "small" | "Big"
