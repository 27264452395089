import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from "@angular/core"
import { FormGroup } from "@angular/forms"

@Component({
  selector: "ag-drop-down",
  templateUrl: "./ag-drop-down.component.html",
  styleUrls: ["./ag-drop-down.component.scss"],
})
export class AgDropDownComponent implements OnInit {
  //data
  @Input() list: LabelValue[] = []
  @Input() currentValue: LabelValue
  @Input() currentValueMultiple: LabelValue[]

  //Layout
  @Input() useFixedPosition = false
  @Input() showSearchField = true
  @Input() multipleOutput = false //need to be set to true when you use currentValueMultiple
  @Input() hasIcons: boolean = false
  @Input() noIconText: boolean = false
  @Input() error: boolean = false
  @Input() dropDownTitle = "" //remember to give '' if you don't want a title
  @Input() hint = "" //This can only be used if you don't use currentValue or currentValueMultiple
  @Input() infoMessage: string
  @Input() errorMessage: string
  @Input() staticIcon = "" //Used when u need to show icon in the dropdown just icon
  @Input() type: AgType = "text-single"
  @Input() disabledInputs: LabelValue[] = [] //Used in the multiple section, for disabling inputs
  @Input() hintForDisabledInputs: string = "" //Used in the multiple section, for displaying hints next to disabled inputs
  @Input() chooseMultipleCheckmark: boolean = true
  @Input() checkboxType: checkboxType = "square"

  //css
  @Input() size: AgSize = "small"
  @Input() disable = false
  @Input() backgroundColor = ""
  @Input() maxHeight: number = 259
  @Input() minWidth: number
  @Input() maxWidth: number
  @Input() maxHeightNotClicked: number = 0
  @Input() dropDownWidth?: number

  @Output() changedValue = new EventEmitter<LabelValue>()
  @Output() changedValueMultiple = new EventEmitter<LabelValue[]>()

  @ViewChild("arrowImage") arrowImage

  public currentCurrency = 0

  public currencyForm: FormGroup
  public hideSearchBox = true

  @HostListener("document:click", ["$event"])
  clickedOutside(event) {
    if (!this.eRef.nativeElement.contains(event.target) && !this.hideSearchBox) {
      this.hideSearchBox = true
      this.arrowImage.nativeElement.style.transform = `rotate(${this.hideSearchBox ? 0 : 180}deg)`
    }
  }

  constructor(private eRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    if (this.dropDownTitle || this.type === "country-code") {
      this.size = "large"
    }
  }

  public onOutput(value: LabelValue): void {
    this.currentValue = value
    this.changedValue.emit(value)
    this.hideSearchBox = true
    this.arrowImage.nativeElement.style.transform = `rotate(${this.hideSearchBox ? 0 : 180}deg)`
  }
  public unSectionOutput(value: LabelValue[]): void {
    this.changedValueMultiple.emit(value)
  }

  public onOutputMultiple(value: LabelValue[]): void {
    this.currentValueMultiple = value
    this.changedValueMultiple.emit(value)
  }

  public onSearchBoxFieldClicked(value: boolean): void {
    this.onFieldClicked()
  }

  public onFieldClicked(): void {
    if (!this.disable) {
      this.hideSearchBox = !this.hideSearchBox
      this.arrowImage.nativeElement.style.transform = `rotate(${this.hideSearchBox ? 0 : 180}deg)`
    }
  }
}

export interface LabelValue {
  label: string
  value: any
}
export type AgSize = "small" | "large"
export type checkboxType = "square" | "circle"
export type AgType = "text-single" | "text-multiple" | "country-code" | "right-checkMark" | "text-multiple-section"
