<ng-container *ngIf="numberOfComponents?.length === 1">
  <div class="main-component" #viewControl [ngStyle]="{ 'min-height': simpleVersion ? '0px' : '', width: styles.specificWidth ? styles.specificWidth : '', opacity: disable ? 0.5 : 1 }">
    <div
      [ngClass]="isInputFocused || (isInputHovered && !disable) ? 'input-container-focused' : 'input-container'"
      (mouseenter)="onEnter()"
      (mouseleave)="onLeave()"
      [ngStyle]="{
        border: !isInputFocused && !isInputHovered && info?.type === 'error' && !disable ? '1px solid #E55E5E' : styles.border,
        'background-color': !isInputFocused && !isInputHovered && styles.backgroundColor && !disable ? styles.backgroundColor : '',
        height: styles.specificHeight ? styles.specificHeight : '',
        'min-height': styles.specificHeight ? 'fit-content' : '56px'
      }"
      (click)="clickInput()">
      <div class="ag-value" #agValue [ngStyle]="{ width: imgPath ? '90%' : '100%' }">
        <label class="label" *ngIf="label" for="input" [class.above]="isInputFocused || inputMessage?.length > 0" [ngStyle]="{ color: styles.labelColor }">{{ label }}</label>
        <input
          class="input"
          [id]="fieldId"
          [name]="fieldId"
          [placeholder]="showPlaceholder()"
          [type]="inputType"
          [formControl]="control"
          [value]="initValue"
          [disabled]="disable"
          (focus)="onFocus()"
          (blur)="onBlur()"
          (input)="onInput($event)"
          (change)="onChange($event)"
          autocomplete="off"
          [ngStyle]="{
            height: isInputFocused ? '100%' : '26px',
            transform: (isInputFocused && label) || (label && inputMessage?.length > 0) ? 'translateY(9px)' : '',
            'background-color': !isInputFocused && !isInputHovered && styles.backgroundColor && !disable ? styles.backgroundColor : ''
          }"
          [ngClass]="isInputFocused || (isInputHovered && !disable) ? 'input-focused' : 'input'" />
      </div>
      <img *ngIf="imgPath && inputType !== 'password'" class="input-img" [src]="imgPath" />
      <img *ngIf="isPassword && inputType === 'password'" style="cursor: pointer" [src]="iconService.Icon.eye_closed" (click)="inputType = 'text'" />
      <img *ngIf="isPassword && inputType === 'text'" style="cursor: pointer" [src]="iconService.Icon.eye_open" (click)="inputType = 'password'" />
    </div>
    <div class="info-container" *ngIf="info?.showInfo">
      <img class="info-img" [src]="iconService.Icon.i_letter_1_5" *ngIf="info.type === 'info'" />
      <img class="error-img" [src]="iconService.Icon.cross_1_5" *ngIf="info.type === 'error'" />
      <div class="info-text" [ngStyle]="{ color: info.type === 'info' ? '#222222' : '#E55E5E' }">
        {{ info.text }}
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="numberOfComponents?.length > 1">
  <div class="numbered-main-component" #viewControl [ngStyle]="{ width: styles.specificWidth ? styles.specificWidth : '', opacity: disable ? 0.5 : 1 }">
    <div class="inner-container">
      <div
        *ngFor="let item of numberOfComponents; let i = index"
        class="numbered-input-container"
        [ngStyle]="{
          border: invalidInputIndex.includes(i) ? '1px solid #E55E5E' : '' || i === hoveredIndex || i === focusedIndex ? '1px solid #DFDFDF' : '' || styles.border ? styles.border : '',
          'background-color': i === hoveredIndex || i === focusedIndex ? '#FFFFFF' : '#F2F2F2' || styles.backgroundColor ? styles.backgroundColor : '#F2F2F2'
        }"
        (mouseenter)="numberedEnter(i)"
        (mouseleave)="numberedLeave()">
        <input
          class="numbered-input"
          [ngStyle]="{ 'background-color': i === hoveredIndex || i === focusedIndex ? '#FFFFFF' : '#F2F2F2' || styles.backgroundColor ? styles.backgroundColor : '#F2F2F2' }"
          #InputRef
          type="number"
          [formControl]="control"
          [value]="initValue"
          [disabled]="disable"
          (input)="changeNumberedInput($event, i)"
          (focus)="numberedFocus(i)"
          (blur)="numberedBlur()"
          id="input-{{ i }}"
          maxlength="1" />
      </div>
    </div>
    <div class="info-container" *ngIf="info?.showInfo">
      <img class="info-img" [src]="iconService.Icon.cross_1_5" *ngIf="info.type === 'info'" />
      <img class="error-img" [src]="iconService.Icon.cross_1_5" *ngIf="info.type === 'error'" />
      <div class="info-text" [ngStyle]="{ color: info.type === 'info' ? '#222222' : '#E55E5E' }">
        {{ info.text }}
      </div>
    </div>
  </div>
</ng-container>
