import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'ag-single-box',
  templateUrl: './ag-single-box.component.html',
  styleUrls: ['./ag-single-box.component.scss']
})
export class AgSingleBoxComponent implements OnInit {

  @Input() list: LabelValue[] = [];
  @Input() showBox = false;
  @Input() showSearchField = true;
  @Input() useFixedPosition = false;
  @Input() showHeaders: boolean = false;
  @Input() maxHeight: number = 1000;
  @Input() currentValue: LabelValue;


  @Output() output = new EventEmitter<LabelValue>();
  @Output() searchBoxFieldClicked = new EventEmitter<boolean>();

  @ViewChild('inputField', { static: false }) inputField: ElementRef;
  @ViewChild('arrowImage') arrowImage;

  public displayedList: LabelValue[] = [];
  public searchForm: FormGroup;
  public isTyping = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchInput: ['']
    });
    this.displayedList = this.list;
  }

  onInputChange(value: string) {
    if (value === '') {
        this.isTyping = false;
        this.displayedList = this.list;
    } else {
        this.isTyping = true;
        this.displayedList = this.list.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    }
  }

  showBoxClicked() {
    if (!this.isTyping || (this.inputField && this.inputField.nativeElement && this.inputField.nativeElement.value === '')) {
        this.displayedList = this.list;
    }
    return true;
  }

  onItemClicked(value: LabelValue) {
    this.currentValue = value;
    this.output.emit(value);
    this.showBox = false;
  }

  onFieldClicked() {
    this.showBox = false;
    this.searchBoxFieldClicked.emit(true);
  }

  isSelected(item: LabelValue): boolean{
    return this.currentValue !== null ?? this.currentValue.label === item.label;
  }
}

export interface LabelValue {
  label: string;
  value: any;
}
