import { animate, state, style, transition, trigger } from "@angular/animations"
import { Component, Input, EventEmitter, Output, OnInit, HostListener, OnChanges, SimpleChanges } from "@angular/core"
import * as moment from "moment"
import { IconImageService } from "../services/icon-image.service"
import { DatePipe } from "@angular/common"
import { InputInfo } from "@shared/components/ag-input-field/ag-input-field.component"

@Component({
  selector: "datePicker-component",
  template: `
    <div style="position: relative;">
      <div [ngStyle]="{ width: forceWidth ? forceWidth : '', height: forceHeight }" [ngClass]="isDateRange ? 'wide' : 'thin'">
        <div [ngClass]="datepickerShown ? 'date-picker-white' : 'date-picker'" [ngStyle]="{ height: forceHeight }" *ngIf="useOpenButton" (click)="openDatePicker()">
          <p class="text" *ngIf="!!labelInput">{{ this.labelInput }}</p>
          <p class="text" *ngIf="!isDateRange && changedDate && placeholder" [ngClass]="datepickerShown ? 'dark' : 'grey'">{{ this.selectedStartDate | date : "dd MMM YYYY" }}</p>
          <p class="text" *ngIf="!changedDate && placeholder" [ngClass]="datepickerShown ? 'dark' : 'grey'">{{ placeholder }}</p>
          <p class="text" *ngIf="!placeholder && startDate && !isDateRange" [ngClass]="datepickerShown ? 'dark' : 'grey'">{{ this.selectedStartDate | date : "dd MMM YYYY" }}</p>
          <p class="text" *ngIf="isDateRange && changedDate" [ngClass]="datepickerShown ? 'dark' : 'grey'">
            {{ this.selectedStartDate | date : "dd MMM YYYY" }} -
            {{ this.selectedEndDate | date : "dd MMM YYYY" }}
          </p>
          <img style="filter: invert(73%) sepia(0%) saturate(1%) hue-rotate(154deg) brightness(94%) contrast(83%);" [src]="iconService.Icon.calendar" />
        </div>
      </div>
      <div *ngIf="info.showInfo" style="display: flex; gap: 8px; align-items: center; margin-top: 8px">
        <img width="12" height="12" [src]="info.type === 'error' ? iconService.Icon.cross_1_5 : iconService.Icon.exclamation_mark_1_5" [ngClass]="info.type === 'error' ? 'red-img' : 'black-img'" />
        <p class="info-text" [ngStyle]="{ color: info.type === 'error' ? '#E55E5E' : '#222222' }">{{ info.text }}</p>
      </div>

      <div (click)="insideClick = true">
        <div class="custom-datepicker placement-{{ placement }}" [@fadeInOut]="datepickerShown ? 'in' : 'out'" *ngIf="datepickerShown">
          <div class="header">
            <img class="image" [src]="iconService.Icon.left_1_5" (click)="previousMonth()" />
            <span class="label">{{ label }}</span>
            <img class="image" [src]="iconService.Icon.right_1_5" (click)="nextMonth()" />
          </div>
          <div class="button-area" *ngIf="isDateRange">
            <ag-button-v2 specificWidth="140px" size="small" label="Today" [type]="currentDate === 'today' ? 'primary' : 'secondary'" (click)="selectToday()" [disabled]="todayOrTomorrow()"></ag-button-v2>
            <ag-button-v2 specificWidth="140px" size="small" label="Tomorrow" [type]="currentDate === 'tomorrow' ? 'primary' : 'secondary'" (click)="selectTomorrow()"></ag-button-v2>
          </div>
          <div class="calendar" *ngIf="datepickerShown">
            <table>
              <thead>
                <tr style="height: 18px">
                  <th>
                    <p class="week">W</p>
                    <div class="br-line"></div>
                  </th>
                  <th *ngFor="let day of weekdays">
                    <p class="week">{{ day }}</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let week of weeks; let i = index">
                  <td class="not-in-month" [class.no-hover]="!isDateRange" [class.in-month]="inWeek(week)" (click)="toogleWeekSelection(week[0], week[6])">
                    {{ calculateWeekNumber(week[1]) }}
                  </td>
                  <td *ngFor="let day of week" [class.selected]="isDateSelected(day)" [class.last-date]="isLastDate(day)" [class.first-date]="isFirstDate(day)">
                    <div *ngIf="!isFirstDate(day) && !isLastDate(day)" class="not-in-month" [class.in-month]="(isBeforeCurrentDate(day) || isAfterCurrentDate(day)) && (isInSpecificMonth(day) || isDateSelected(day))" (click)="toggleDateSelection(day)">
                      {{ day | date : "d" }}
                    </div>
                    <div *ngIf="isFirstDate(day) || isLastDate(day)" class="first-last-date" (click)="toggleDateSelection(day)">
                      {{ day | date : "d" }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="button-area" *ngIf="applyButton">
            <ag-button-v2 specificWidth="140px" size="medium" label="Cancel" type="secondary" (click)="return()"></ag-button-v2>
            <ag-button-v2 specificWidth="140px" size="medium" label="Apply" type="primary" (click)="submit()" [disabled]="newDates()"></ag-button-v2>
          </div>
        </div>
      </div>
    </div>
  `,
  animations: [trigger("fadeInOut", [state("in", style({})), transition(":enter", [style({ height: "100%" }), animate("0.2s ease-in-out")]), transition(":leave", [animate("0.2s ease-in-out", style({ height: "0%" }))])])],
  styles: [
    `
      .date-picker {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px 12px 16px;
        border-radius: 12px;
        background: #f2f2f2;
        max-height: 56px;
        font-size: 14px;
        font-weight: 400;
        font-family: "Poppins-Regular";
        cursor: pointer;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        gap: 8px;
        width: 100%;
      }
      .text {
        font-family: "Poppins-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
      }
      .grey {
        color: #5c5c5c;
      }
      .dark {
        color: #222;
      }
      .wide {
        width: 320px;
      }
      .thin {
        width: 200px;
      }

      .date-picker-white {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px 12px 16px;
        border-radius: 12px;
        background: #ffffff;
        position: relative;
        z-index: 5;
        height: 48px;
        max-height: 48px;
        font-size: 14px;
        font-weight: 400;
        font-family: "Poppins-Regular";
        cursor: pointer;
        border: 1px solid #222;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        gap: 8px;
        width: 100%;
      }

      .custom-datepicker {
        position: absolute;
        z-index: 4;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #fff;
        border-radius: 16px;
        border: 1px solid #222;
        margin-top: 4px;
        padding: 16px;
        overflow: hidden;
      }
      .placement-top {
        top: 0px;
      }
      .placement-bottom {
        bottom: 52px;
      }
      .placement-right {
        right: 101%;
      }
      .placement-zero-right {
        right: 0%;
      }
      .placement-zero-left {
        left: 0%;
      }
      .placement-left {
        left: 101%;
      }
      .placement-top-left {
        top: 0px;
        left: 101%;
      }
      .placement-top-right {
        top: 0px;
        right: 101%;
      }
      .placement-bottom-left {
        bottom: 52px;
        left: 101%;
      }
      .placement-bottom-right {
        bottom: 52px;
        left: 101%;
      }
      .placement-bottom-just-left {
        right: 0;
      }

      .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .image {
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
      .label {
        font-family: "Poppins-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #222;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      td {
        width: 36px;
        height: 36px;
        padding: 4px 7px;
        margin: 2px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
      }
      td:not(:has(.in-month)):hover {
        cursor: default;
        background: transparent;
      }
      td:hover {
        border-radius: 8px;
        background: #f2f2f2;
      }
      .no-hover {
        cursor: default;
      }
      .no-hover:hover {
        background: transparent;
      }
      th {
        width: 18px;
        height: 18px;
        margin: 7px 9px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .br-line {
        height: 90%;
        left: 33px;
        top: 24px;
        width: 1px;
        background: #dfdfdf;
        position: absolute;
        z-index: 2;
      }
      .button-area {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 8px;
        padding-top: 24px;
      }
      .calendar {
        position: relative;
        padding-top: 24px;
        overflow: hidden;
      }
      .week {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Poppins-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: #a6a6a6;
        margin: 0;
      }
      .not-in-month {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Poppins-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #a6a6a6;
        margin: 0;
      }
      .in-month {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Poppins-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #222;
        margin: 0;
      }
      .first-last-date {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Poppins-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        background-color: #222 !important;
        color: #fff;
        width: 36px;
        height: 36px;
        border-radius: 8px;
        border-top: 1px solid #222;
        border-bottom: 1px solid #222;
      }
      .first-date {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        background-color: #f2f2f2;
        padding: 0;
      }
      .last-date {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: #f2f2f2;

        padding: 0;
      }
      .white {
        color: #fff !important;
      }
      .selected {
        background-color: #f2f2f2;
      }
      .info-text {
        font-family: "Poppins-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
      .red-img {
        filter: invert(51%) sepia(43%) saturate(791%) hue-rotate(314deg) brightness(92%) contrast(95%);
      }
    `,
  ],
})
export class DatePickerComponent implements OnInit, OnChanges {
  selectedMonthYear: Date = new Date()
  weekdays: string[] = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]
  weeks: Date[][] = []
  selectedStartDate: Date | null = null
  selectedEndDate: Date | null = null
  label: any
  currentDate = "today"
  datepickerShown = false
  insideClick: boolean
  changedDate = false
  @Output() dateRangeSelected: EventEmitter<Date[]> = new EventEmitter<Date[]>()
  @Input() startDate?: Date = null
  @Input() endDate?: Date = null
  @Input() isDateRange?: boolean = false
  @Input() labelInput?: string = null
  @Input() placeholder?: string = null
  @Input() applyButton?: boolean = true
  @Input() placement: Placement //if you want to change the left/right position just give the datepicker a parent div & change its width
  @Input() disableDates: DisableDates = "none"
  @Input() forceWidth?: string = null
  @Input() forceHeight?: string = "48px"
  @Input() useOpenButton: boolean = true
  @Input() triggerOpenDatePicker: boolean = false
  @Input() maxDateRangeSelectable: number = 14

  @Input() info: InputInfo = {
    showInfo: false,
    type: "info",
    text: "Info",
  }

  @HostListener("document:click", ["$event", "$event.target"])
  public onClick(): void {
    if (this.insideClick === false) {
      this.datepickerShown = false
    } else this.insideClick = false
  }

  constructor(public iconService: IconImageService, private datePipe: DatePipe) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedStartDate = this.startDate
    this.selectedEndDate = this.endDate
    if (!changes.triggerOpenDatePicker?.firstChange && changes.triggerOpenDatePicker !== undefined) {
      this.openDatePicker()
    }
  }

  ngOnInit() {
    this.initializeDates()
    this.generateCalendar()
  }

  initializeDates() {
    if (this.startDate !== null) {
      var newStartDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate(), 0, 0, 0, 0)
    }
    if (this.endDate !== null) {
      var newEndDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate(), 0, 0, 0, 0)
    }
    if (this.isDateRange) {
      this.selectedEndDate = newEndDate
      this.selectedStartDate = newStartDate
    }
    if (!this.isDateRange && this.startDate !== null) {
      this.selectedStartDate = newStartDate
    }
    if (!this.isDateRange && this.startDate == null) {
      const date = new Date()
      this.selectedStartDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
    }
  }

  return() {
    if (this.isDateRange) this.dateRangeSelected.emit([this.startDate, this.endDate])
    else this.dateRangeSelected.emit([this.startDate])
    this.datepickerShown = false
    this.initializeDates()
  }

  generateCalendar() {
    const year = this.selectedMonthYear.getFullYear()
    const month = this.selectedMonthYear.getMonth()
    const firstDay = new Date(year, month, 1)
    const lastDay = new Date(year, month + 1, 0)
    const startDate = new Date(firstDay)

    this.label = moment(this.selectedMonthYear).format("MMMM YYYY")
    const firstDayOfWeek = (startDate.getDay() + 6) % 7

    startDate.setDate(startDate.getDate() - firstDayOfWeek)

    this.weeks = []

    while (startDate <= lastDay) {
      const week: Date[] = []
      for (let i = 0; i < 7; i++) {
        week.push(new Date(startDate))
        startDate.setDate(startDate.getDate() + 1)
      }
      this.weeks.push(week)
    }
  }

  calculateWeekNumber(date: Date): number {
    const startDate = new Date(date.getFullYear(), 0, 1)
    const days = Math.floor((date.getTime() - startDate.getTime()) / (24 * 3600 * 1000))
    const weekNumber = Math.ceil((days + startDate.getDay() + 1) / 7)
    return weekNumber
  }

  todayOrTomorrow(): boolean {
    if (moment(this.selectedStartDate).format("YYYY MMMM DD") === moment(new Date()).format("YYYY MMMM DD")) {
      this.currentDate = "today"
      this.changedDate = true
    } else if (moment(this.selectedStartDate).format("YYYY MMMM DD") === moment(new Date()).add(1, "days").format("YYYY MMMM DD")) {
      this.currentDate = "tomorrow"
      this.changedDate = true
    } else this.currentDate = "neither"
    return false
  }

  newDates(): boolean {
    var retVal = false
    if (this.isDateRange) {
      if (moment(this.selectedStartDate).format("YYYY MMMM DD") === moment(this.startDate).format("YYYY MMMM DD") && moment(this.selectedEndDate).format("YYYY MMMM DD") === moment(this.endDate).format("YYYY MMMM DD")) retVal = true
    } else {
      if (moment(this.selectedStartDate).format("YYYY MMMM DD") === moment(this.startDate).format("YYYY MMMM DD")) retVal = false
    }
    return retVal
  }

  previousMonth() {
    this.selectedMonthYear.setMonth(this.selectedMonthYear.getMonth() - 1)
    this.generateCalendar()
  }

  nextMonth() {
    this.selectedMonthYear.setMonth(this.selectedMonthYear.getMonth() + 1)
    this.generateCalendar()
  }

  toggleDateSelection(date: Date) {
    if (this.isDateDisabled(date)) {
      return
    } else {
      this.changedDate = true
      if (this.isDateRange) {
        if (this.selectedEndDate !== null) {
          this.selectedStartDate = date
          this.selectedEndDate = null
        } else if (date < this.selectedStartDate) {
          this.selectedStartDate = date
          this.selectedEndDate = null
        } else if (date >= this.selectedStartDate) {
          if ((date.getTime() - this.selectedStartDate.getTime()) / (1000 * 3600 * 24) > this.maxDateRangeSelectable) {
            this.selectedStartDate = date
            this.selectedEndDate = null
          } else this.selectedEndDate = date
        }
      } else {
        this.selectedStartDate = date
      }

      if (!this.applyButton) this.submit()
    }
  }

  toogleWeekSelection(mon: Date, sun: Date) {
    if (this.isDateRange) {
      this.toggleDateSelection(mon)
      this.toggleDateSelection(sun)
    }
  }

  isInSpecificMonth(date: Date): boolean {
    var targetMonth = this.selectedMonthYear.getMonth()
    var targetYear = this.selectedMonthYear.getFullYear()
    const dateMonth = date.getMonth()
    const dateYear = date.getFullYear()
    return dateMonth === targetMonth && dateYear === targetYear
  }

  isDateSelected(date: Date): boolean {
    if (date !== this.selectedStartDate && date !== this.selectedEndDate && this.isDateRange) {
      return this.selectedStartDate && this.selectedEndDate && date > this.selectedStartDate && date < this.selectedEndDate
    }
  }

  isDateDisabled(day: Date): boolean {
    const newDate = new Date()
    const currentDate = new Date(this.datePipe.transform(newDate, "yyyy-MM-dd"))
    const selectedDate = new Date(this.datePipe.transform(day, "yyyy-MM-dd"))
    if (this.disableDates === "future") {
      if (currentDate < selectedDate) {
        return true
      } else if (currentDate === selectedDate) {
        return false
      } else {
        return false
      }
    } else if (this.disableDates === "past") {
      if (currentDate > selectedDate) {
        return true
      } else if (currentDate === selectedDate) {
        return false
      } else {
        return false
      }
    } else {
      return false
    }
  }

  isBeforeCurrentDate(day: Date): boolean {
    if (this.disableDates === "none") {
      return true
    } else {
      if (this.disableDates === "past") {
        const newDate = new Date()
        const currentDate = new Date(this.datePipe.transform(newDate, "yyyy-MM-dd"))
        const selectedDate = new Date(this.datePipe.transform(day, "yyyy-MM-dd"))
        if (currentDate < selectedDate) {
          return true
        } else if (currentDate.getTime() === selectedDate.getTime()) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }

  isAfterCurrentDate(day: Date): boolean {
    if (this.disableDates === "none") {
      return true
    } else {
      if (this.disableDates === "future") {
        const newDate = new Date()
        const currentDate = new Date(this.datePipe.transform(newDate, "yyyy-MM-dd"))
        const selectedDate = new Date(this.datePipe.transform(day, "yyyy-MM-dd"))
        if (currentDate > selectedDate) {
          return true
        } else if (currentDate.getTime() === selectedDate.getTime()) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }

  inWeek(week: Date[]): boolean {
    var value = false
    week.forEach((date) => {
      var x = this.isDateSelected(date)
      var y = this.isLastDate(date)
      var z = this.isFirstDate(date)
      x ? (value = true) : ""
      y ? (value = true) : ""
      z ? (value = true) : ""
    })
    return value
  }

  isFirstDate(isDate: Date): boolean {
    if (this.selectedStartDate !== null && this.selectedStartDate !== undefined) {
      var newStartDate = moment(new Date(this.selectedStartDate.getFullYear(), this.selectedStartDate.getMonth(), this.selectedStartDate.getDate(), 0, 0, 0, 0)).format("DD MMM YYYY")
      var date = moment(isDate).format("DD MMM YYYY")
      return date === newStartDate
    }
  }

  isLastDate(isDate: Date): boolean {
    if (this.selectedEndDate !== null && this.selectedEndDate !== undefined) {
      var newEndDate = moment(new Date(this.selectedEndDate.getFullYear(), this.selectedEndDate.getMonth(), this.selectedEndDate.getDate(), 0, 0, 0, 0)).format("DD MMM YYYY")
      var date = moment(isDate).format("DD MMM YYYY")
      return date === newEndDate
    }
  }
  openDatePicker() {
    this.insideClick = true
    this.datepickerShown = !this.datepickerShown
  }

  getWeekNumber(date: Date): number {
    return moment(date).isoWeek()
  }
  selectToday() {
    if (this.currentDate !== "today") {
      const date = new Date()
      if (this.isDateRange) {
        const tomorrow = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, 0)
        this.selectedEndDate = tomorrow
      }
      const today = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
      this.currentDate = "today"
      this.selectedStartDate = today
    }
  }

  selectTomorrow() {
    if (this.currentDate !== "tomorrow") {
      const date = new Date()
      if (this.isDateRange) {
        const tomorrowtomorrow = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2, 0, 0, 0, 0)
        this.selectedEndDate = tomorrowtomorrow
      }
      const tomorrow = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, 0)
      this.currentDate = "tomorrow"
      this.selectedStartDate = tomorrow
    }
  }
  submit() {
    this.changedDate = true
    if (this.isDateRange) this.dateRangeSelected.emit([this.selectedStartDate, this.selectedEndDate])
    else this.dateRangeSelected.emit([this.selectedStartDate])
    this.datepickerShown = false
  }
}

export type Placement = "top" | "left" | "right" | "bottom" | "top-left" | "top-right" | "bottom-left" | "bottom-right" | "bottom-just-left" | "zero-right" | "zero-left"

export type DisableDates = "none" | "future" | "past"
