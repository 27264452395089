<div style="height: fit-content; max-height: 100%; width: 100%; position: relative" [hidden]="!showBox ? showBoxClicked() : false">
  <div [ngClass]="!useFixedPosition ? 'container-standard' : 'container-fixed'">
    <div class="form-div" *ngIf="showSearchField">
      <div class="search-bar">
        <input class="search-bar-input" #inputField placeholder="Search" (input)="onInputChange(inputField.value)" />
        <img src="https://aeroguestblobstorageprod.blob.core.windows.net/icons23/Other/Other/magnifier.svg" class="form-img" />
      </div>
    </div>
    <div class="line"></div>
    <div class="top-bottom-padding" [ngStyle]="{ 'max-height': maxHeight - 43 + 'px' }">
      <div [hidden]="list.length < 1">
        <label class="header" [hidden]="isTyping" *ngIf="showHeaders">ALPHABETICAL</label>
        <div class="spacer" *ngIf="showHeaders"></div>
        <div class="items-container">
          <div *ngFor="let country of displayedList" (click)="onItemClicked(country)">
            <div class="hovering-item">
              <div class="selected-country" *ngIf="currentValue?.value === '+' + country.CallingCodes[0]">
                <div class="name">
                  <p class="selected">{{ country.NativeName }}</p>
                  <p class="selected-12px">{{ country.Name }}</p>
                </div>
                <p class="selected">+{{ country.CallingCodes }}</p>
              </div>
              <div class="country" *ngIf="currentValue?.value !== '+' + country.CallingCodes[0]">
                <div class="name">
                  <p class="black">{{ country.NativeName }}</p>
                  <p class="grey">{{ country.Name }}</p>
                </div>
                <p class="black">+{{ country.CallingCodes }}</p>
              </div>
            </div>
          </div>
          <div class="no-result-text" *ngIf="displayedList?.length === 0">No results</div>
        </div>
      </div>
    </div>
  </div>
</div>
