<button type="button" [ngStyle]="specificWidth && { width: specificWidth }" [ngClass]="disabled ? 'ag-type-disabled-' + type : ''" class="ag-button ag-type-{{ type }}-destructive-{{ destructive }} ag-size-{{ size }} ag-state-{{ state }}" [disabled]="disabled" [class.padded]="label">
  <div class="flex-row flex-center flex-align-center" style="display: flex; gap: 8px">
    <span
      *ngIf="label && !imagePath"
      [ngStyle]="{
        'padding-right': loading || !isLoadable ? '0px' : '14.5px',
        'padding-left': loading || !isLoadable ? '0px' : '14.5px',
        color: labelColor ? labelColor : ''
      }">
      {{ label }}
      <div *ngIf="loading" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </span>
    <span
      *ngIf="label && imagePath"
      [ngStyle]="{
        'padding-right': loading || imagePlacement === 'right' ? '0px' : loading ? '21px' : '0px',
        color: labelColor ? labelColor : ''
      }">
      <img *ngIf="!loading && imagePlacement === 'left'" [ngStyle]="{ height: imgSize ? imgSize : '', width: imgSize ? imgSize : '' }" [ngClass]="{ 'filter-black-image': blackImg && type === 'primary' }" [src]="imagePath" />
      <div *ngIf="loading && imagePlacement === 'left'" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {{ label }}
      <img *ngIf="!loading && imagePlacement === 'right'" [ngStyle]="{ height: imgSize ? imgSize : '', width: imgSize ? imgSize : '' }" [ngClass]="{ 'filter-black-image': blackImg && type === 'primary' }" [src]="imagePath" />
      <div *ngIf="loading && imagePlacement === 'right'" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </span>
    <span *ngIf="!label && imagePath" style="width: 24px">
      <img *ngIf="!loading" [ngStyle]="{ height: imgSize ? imgSize : '', width: imgSize ? imgSize : '' }" [ngClass]="{ 'filter-black-image': blackImg && type === 'primary' }" [src]="imagePath" />
      <div *ngIf="loading" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </span>
  </div>
</button>
