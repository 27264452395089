import { Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Renderer2, Self, ViewChild } from "@angular/core"
import { AbstractControl, UntypedFormControl, NgControl, ValidationErrors, Validator } from "@angular/forms"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-input-field",
  templateUrl: "./ag-input-field.component.html",
  styleUrls: ["./ag-input-field.component.scss"],
})
export class AgInputFieldComponent implements Validator, OnInit {
  @Input() label: string = ""
  @Input() placeholder: string = ""
  @Input() hint?: string = ""
  @Input() inputType?: InputType = "text"
  @Input() imgPath: string = ""
  @Input() disable: boolean = false
  @Input() initValue: string = ""
  @Input() isPassword?: boolean = false
  @Input() fieldId: string = "input"
  @Input() simpleVersion: boolean = false

  @Input() styles: InputStyles = {
    labelColor: "",
    backgroundColor: "",
    border: "",
    specificWidth: "314px",
    specificHeight: "",
  }
  @Input() info: InputInfo = {
    showInfo: false,
    type: "info",
    text: "Info",
  }

  //This Input is only needed for multiple inputs in one component
  @Input() numberOfComponents: Array<number> = [0]
  //if you want to more have more inputs and give them error make sure
  //that the numbers in this array match the index of the inputs from numberOfComponents
  @Input() invalidInputIndex: Array<number>

  @Output() inputResult = new EventEmitter()
  @Output() changeResult = new EventEmitter()

  @ViewChild("viewControl") viewControl?: ElementRef
  @ViewChild("agValue") agValue?: ElementRef

  public control: UntypedFormControl
  public isInputFocused: boolean = false
  public isInputHovered: boolean = false
  public inputMessage: string
  public labelClicked: boolean = false

  public focusedIndex: number
  public hoveredIndex: number

  constructor(@Optional() @Self() private _ngControl: NgControl, private renderer: Renderer2, public iconService: IconImageService, private el: ElementRef) {
    if (this._ngControl) {
      this._ngControl.valueAccessor = this
    }
  }

  ngOnInit(): void {
    if (this.initValue.length > 0) {
      this.labelClicked = true
      this.inputMessage = this.initValue
    }
    if (this._ngControl?.control) {
      this.control = this._ngControl.control as UntypedFormControl
    } else {
      this.control = new UntypedFormControl({ value: this.initValue, disabled: this.disable })
    }
    this.control.valueChanges.subscribe((x) => {
      this.inputResult.emit(x)
    })
  }

  writeValue() {}
  registerOnChange() {}
  registerOnTouched() {}

  validate(control: AbstractControl): ValidationErrors | null {
    return this.control.valid ? null : control.errors
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.control) {
        this.initValue = this.control.value
        this.inputMessage = this.control.value
        this.labelClicked = true
      }
    }, 1000)
  }

  onFocus() {
    this.isInputFocused = true
  }

  onBlur() {
    this.isInputFocused = false
    this.labelClicked = false
  }

  numberedEnter(index: number) {
    this.hoveredIndex = index
  }

  numberedLeave() {
    this.hoveredIndex = null
  }

  numberedFocus(index: number) {
    this.focusedIndex = index
  }

  numberedBlur() {
    this.focusedIndex = null
  }

  onEnter() {
    this.isInputHovered = true
  }

  onLeave() {
    this.isInputHovered = false
  }

  public showPlaceholder(): string {
    if (this.isInputFocused || !this.label) {
      return this.placeholder
    } else {
      return ""
    }
  }

  public clickInput() {
    this.labelClicked = true
    this.el.nativeElement.querySelector("input").focus()
  }

  onInput(event: Event) {
    this.inputMessage = (event.target as HTMLInputElement).value
    this.inputResult.emit(this.inputMessage)
  }

  onChange(event: Event) {
    this.inputMessage = (event.target as HTMLInputElement).value
    this.changeResult.emit(this.inputMessage)
  }

  isInputValid(input: string): boolean {
    return !isNaN
  }

  changeNumberedInput(event: Event, index: number) {
    const inputElement = event.target as HTMLInputElement
    const previousValue = inputElement.dataset.previousValue || ""
    const value = inputElement.value

    const isDelete = value.length < previousValue.length

    if (value.length > 1) {
      inputElement.value = value.charAt(0)
    }

    inputElement.dataset.previousValue = value

    if (isDelete) {
      const prevIndex = index - 1
      const prevInput = document.getElementById(`input-${prevIndex}`) as HTMLInputElement | null

      if (prevInput) {
        prevInput.focus()
      }
    } else {
      const nextIndex = index + 1
      const nextInput = document.getElementById(`input-${nextIndex}`) as HTMLInputElement | null
      if (nextInput) {
        nextInput.focus()
      }
    }
    this.inputResult.emit(value)
  }
}

export type InputType = "text" | "url" | "tel" | "password" | "email" | "number"

export class InputStyles {
  labelColor?: string
  backgroundColor?: string
  border?: string
  specificWidth?: string
  specificHeight?: string
}

export class InputInfo {
  showInfo?: boolean
  type?: Type
  text?: string
}

export type Type = "info" | "error"
