<div class="label-box" class="{{ color }} {{ secondaryColor }}">
  <img class="img" *ngIf="type === 'withImgLeft' && !loading" [src]="imagePath" />
  <ng-container *ngIf="type === 'withImgLeft' && loading">
    <ng-lottie class="animation" width="16px" height="16px" [options]="options"></ng-lottie>
  </ng-container>
  <span [ngStyle]="{ 'font-weight': fontWeight !== '' ? fontWeight : '' }">{{ label }}</span>
  <ng-container *ngIf="type === 'withImgRight' && loading">
    <ng-lottie class="animation" width="16px" height="16px" [options]="options"></ng-lottie>
  </ng-container>
  <img class="img" *ngIf="type === 'withImgRight' && !loading" [src]="imagePath" />
</div>
