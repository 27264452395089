import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core"
import { IconImageService } from "@shared/services/icon-image.service"

@Component({
  selector: "ag-tags-input",
  templateUrl: "./ag-tags-input.component.html",
  styleUrls: ["./ag-tags-input.component.scss"],
})
export class AgTagsInputComponent implements OnInit {
  @ViewChild("input") input: ElementRef

  public tagsList: string[] = []
  public inputValue: string = ""
  public isInputFocused: boolean = false
  public isInputHovered: boolean = false

  @Input() inputBackgroundColor: string = ""
  @Input() tagsBackgroundColor: string = ""
  @Input() placeholder: string = "Add tag"
  @Input() disable: boolean = false
  @Input() initValue: string[] = []
  @Input() noSpace: boolean = false
  @Output() tagsOutput = new EventEmitter()

  constructor(public iconService: IconImageService) {}

  ngOnInit(): void {
    if (this.initValue?.length > 0) {
      this.tagsList = [...this.initValue]
    }
  }

  public removeTag(tag: string) {
    const index = this.tagsList.indexOf(tag)
    this.tagsList.splice(index, 1)
    this.input.nativeElement.focus()
    this.tagsOutput.emit(this.tagsList)
  }

  public addTag() {
    const tagInput = this.inputValue.trim()
    if (tagInput) {
      if (this.tagsList.find((x) => x === tagInput)) {
      } else {
        this.tagsList.push(tagInput)
      }
    }
    this.inputValue = ""
    this.input.nativeElement.focus()
    this.tagsOutput.emit(this.tagsList)
  }

  onEnter() {
    this.isInputHovered = true
  }

  onLeave() {
    this.isInputHovered = false
  }

  onFocus() {
    this.isInputFocused = true
  }

  onBlur() {
    this.isInputFocused = false
  }
}
